<template>
  <div>
    <div class="row">
      <div class="col-3">
        <label for="">Dépôt <span class="text-danger">*</span> </label>
        <select
          v-model="deposit_reference"
          @change="updateFilteredStocks"
          class="form-select"
        >
          <option value="">---</option>
          <option
            v-for="(deposit, index) in deposits"
            :key="index++"
            :value="deposit.reference"
          >
            {{ deposit.fullName }}
          </option>
        </select>
      </div>

      <div class="col-3">
        <label for="">Catégorie</label>
        <select
          v-model="section_reference"
          @change="updateFilteredStocks"
          class="form-select"
        >
          <option value="">---</option>
          <option
            v-for="(section, index) in deposit_reference !== ''
              ? sections.filter(
                  (p) =>
                    p.deposit_reference != null &&
                    p.deposit_reference == deposit_reference
                )
              : sections"
            :key="index++"
            :value="section.reference"
          >
            {{ section.fullName }}
          </option>
        </select>
      </div>

      <div class="col-4">
        <label for="">Produit</label>
        <select
          v-model="item_reference"
          @change="updateFilteredStocks"
          class="form-select"
        >
          <option value="">---</option>
          <option
            v-for="(item, index) in items.filter(
              (p) => p.section_reference == section_reference
            )"
            :key="index++"
            :value="item.reference"
          >
            {{ item.fullName }}
          </option>
        </select>
      </div>

      <div class="col-2">
        <label for="">Quantité</label>
        <input
          type="text"
          v-model="transferData.quantity"
          class="form-control"
        />
      </div>
    </div>
    <br />
    <div class="row align-items-end">
      <div class="col-3">
        <label for="">
          Transférer à partir du stock <span class="text-danger">*</span>
        </label>
        <select v-model="transferData.fromStockReference" class="form-select">
          <option
            v-for="(stock, index) in filteredStocks"
            :key="index"
            :value="stock.reference"
          >
            {{ stock.fullName }} Quantité : {{ stock.quantity }}
            {{ stock.unit }}
          </option>
        </select>
        <div class="invalid-feedback">Example invalid select feedback</div>
      </div>

      <div class="col-3">
        <label for="">
          Transférer vers le stock <span class="text-danger">*</span>
        </label>
        <select v-model="transferData.toStockReference" class="form-select">
          <option value="">Créer un nouveau stock</option>
          <option
            v-for="(stock, index) in filteredStocks.filter(
              (p) => p.reference != transferData.fromStockReference
            )"
            :key="index"
            :value="stock.reference"
          >
            {{ stock.fullName }} Quantité : {{ stock.quantity }}
            {{ stock.unit }}
          </option>
        </select>
        <div class="invalid-feedback">Example invalid select feedback</div>
      </div>

      <div class="col">
        <label for=""> Désignation de stock </label>
        <input
          type="text"
          v-model="transferData.toStockFullName"
          :disabled="transferData.toStockReference != ''"
          class="form-control"
        />
      </div>

      <div class="col-auto">
        <label for=""></label>
        <button @click="transferQuantity(transferData)" class="btn btn-success">
          <i class="bi bi-plus-circle"></i> Transfer Quantity
        </button>
      </div>
      <div class="col-auto">
        <label for=""> </label>
        <button
          class="btn btn-secondary d-flex align-items-center"
          @click="refresh()"
        >
          <i class="bi bi-arrow-clockwise fs-4 me-2"></i>
          <span> Actualiser </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      transferData: {},
      section_reference: "",
      item_reference: "",
      deposit_reference: "",
      filteredStocks: [],
    };
  },
  computed: {
    ...mapGetters("stock", {
      stocks: "getStocks",
    }),
    ...mapGetters("item", {
      items: "getAll",
    }),
    ...mapGetters("section", {
      sections: "getAll",
    }),
    ...mapGetters("deposit", {
      deposits: "getAll",
    }),
    ...mapGetters("stock", {
      stocks: "getStocks",
    }),
  },
  mounted() {
    this.$store.dispatch("stock/getAll");
    this.$store.dispatch("section/getAll");
    this.$store.dispatch("item/getAll");
    this.$store.dispatch("deposit/getAll");
    this.$store.dispatch("stock/getAll");
  },
  methods: {
    async transferQuantity(data) {
      data.itemReference = this.item_reference;
      try {
        if (data.toStockReference == "---" && data.toStockFullName == "") {
          this.$store.dispatch("", {
            status: "error",
            message:
              "Veuillez saisir une désignation de stock, par exemple « Stock AB »",
          });
        }
        // Call an action in Vuex store to perform the transfer
        else await this.$store.dispatch("stock/transferQuantity", data);

        // Handle success or display a message to the user
        console.log("Quantity transferred successfully");
      } catch (error) {
        // Handle error or display an error message to the user
        console.error("Error transferring quantity:", error.message);
      }
    },

    async updateFilteredStocks() {
      // Call the filtering logic again to update the filteredStocks

      // Check if all three filters (item_reference, section_reference, deposit_reference) are present
      if (this.item_reference != "" && this.section_reference != "") {
        // Filter the stocks array based on the selected item_reference
        return (this.filteredStocks = this.stocks.filter((stock) => {
          return stock.item_reference == this.item_reference;
        }));
      }

      // Check if both section_reference and deposit_reference filters are present
      else if (this.section_reference != "") {
        // Filter the stocks array based on either the selected section_reference or deposit_reference
        return (this.filteredStocks = this.stocks.filter((stock) => {
          return (
            stock.section_reference == this.section_reference ||
            stock.deposit_reference == this.deposit_reference
          );
        }));
      }

      // Check if only deposit_reference filter is present
      else if (this.deposit_reference != "") {
        // Filter the stocks array based on the selected deposit_reference
        return (this.filteredStocks = this.stocks.filter((stock) => {
          return stock.deposit_reference == this.deposit_reference;
        }));
      }

      // Return an empty array if none of the filters are present
      else return [];
    },

    async refresh() {
      this.$store.dispatch("section/getAll");
      this.$store.dispatch("item/getAll");
      this.$store.dispatch("deposit/getAll");
      this.$store.dispatch("stock/getAll");
    },
  },
};
</script>
